<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Transaction" icon="right-left" />

    <div class="generalBorderlessBox">
        <div class="row mb-4">
            <div class="col-12">
                <div class="row">
                    <!-- <div class="col-12 text-danger small">sortField: {{sortField}}, sortOrder: {{sortOrder}}</div> -->
                    <div class="col-lg-1 mb-2" style="padding-top: 1px">
                        <div class="btn-group">
                            <button type="button" @click="$router.push({ path: '/backoffice/txn' })" class="btn border text-grey">
                                <fa icon="bars" />
                            </button>
                            <button type="button" @click="$router.push({ path: '/backoffice/txnchart' })" class="btn btn-info text-white">
                                <fa icon="chart-line" />
                            </button>
                        </div> 
                    </div>

                    <div class="col-lg-5 mb-2">
                        <table width="100%" border="0">
                            <tr>
                                <td><Datepicker :modelValue="filterDateFr" @update:modelValue="setDateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker></td>
                                <td class="text-primary fw-bold px-2" > - </td>
                                <td><Datepicker :modelValue="filterDateTo" @update:modelValue="setDateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" class="boxCal" autoApply></Datepicker></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 mb-2">
                <div class="contentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12 col-lg-5 fw-bold align-text-top">Top Usage by Entity</div>
                        <div class="col-12 col-lg-7 mb-3">
                            <div class="pt-2 text-end">
                                <span class="filter-pill-border">
                                    <span class="filter-item-pill" :class="entityOpt === 'FOLDER' ? 'active' : ''" @click="entityOpt = 'FOLDER'">FOLDER</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" :class="entityOpt === 'DOCUMENT' ? 'active' : ''" @click="entityOpt = 'DOCUMENT'">DOC</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" :class="entityOpt === 'CREDITSPENT' ? 'active' : ''" @click="entityOpt = 'CREDITSPENT'">SPENT</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <table class="table table-hover">
                        <tbody>
                            <tr v-for="ent in arrEntUsage" :key="ent">
                                <td>{{ ent.jEntity.shortName }}</td>
                                <td class="text-end">
                                    <span v-if="entityOpt === 'FOLDER'">{{ ent.totalFolder }}</span>
                                    <span v-if="entityOpt === 'DOCUMENT'">{{ ent.totalDocument }}</span>
                                    <span v-if="entityOpt === 'CREDITSPENT'">{{ ent.totalCharge }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-lg-6 mb-2">
                <div class="contentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12 col-lg-5 fw-bold align-text-top">Top Usage by User</div>
                        <div class="col-12 col-lg-7 mb-3">
                            <div class="pt-2 text-end">
                                <span class="filter-pill-border">
                                    <span class="filter-item-pill" :class="userOpt === 'FOLDER' ? 'active' : ''" @click="userOpt = 'FOLDER'">FOLDER</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" :class="userOpt === 'DOCUMENT' ? 'active' : ''" @click="userOpt = 'DOCUMENT'">DOC</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" :class="userOpt === 'CREDITSPENT' ? 'active' : ''" @click="userOpt = 'CREDITSPENT'">SPENT</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <table class="table table-hover">
                        <tbody>
                            <tr v-for="usr in arrUserUsage" :key="usr">
                                <td>
                                    <div>{{ usr.jUser.fullName }}</div>
                                    <div class="small text-grey">{{ usr.jUser.userId }}</div>
                                </td>
                                <td>{{ usr.jEntity.shortName }}</td>
                                <td class="text-end">
                                    <span v-if="userOpt === 'FOLDER'">{{ usr.totalFolder }}</span>
                                    <span v-if="userOpt === 'DOCUMENT'">{{ usr.totalDocument }}</span>
                                    <span v-if="userOpt === 'CREDITSPENT'">{{ usr.totalCharge }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>

            <div class="col-lg-6 mb-2">
                <div class="contentBox border shadow-sm">
                    <div class="fw-bold mb-2">Top Txn by Documents</div>
                    <table class="table table-hover">
                        <tbody>
                            <tr class="isLink">
                                <td>
                                    <div>SignOn.my - enerson.yap@signon.my</div>
                                    <div class="small text-grey">10Jun2023 12:23</div>
                                </td>
                                <td class="text-primary text-end">10</td>
                            </tr>
                            <tr class="isLink">
                                <td>
                                    <div>Genusis - enerson.yap@genusis.com</div>
                                    <div class="small text-grey">18Jun2023 10:30</div>
                                </td>
                                <td class="text-primary text-end">8</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="contentBox border shadow-sm">
                    <div class="fw-bold mb-2">Top Txn by Usage</div>
                    <table class="table table-hover">
                        <tbody>
                            <tr class="isLink">
                                <td>
                                    <div>SignOn.my - enerson.yap@signon.my</div>
                                    <div class="small text-grey">25Jun2023 08:00</div>
                                </td>
                                <td class="text-primary text-end">50</td>
                            </tr>
                            <tr class="isLink">
                                <td>
                                    <div>SignOn.my - enerson.yap@signon.my</div>
                                    <div class="small text-grey">10Jun2023 10:30</div>
                                </td>
                                <td class="text-primary text-end">25</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
// import Avatar from '@/components/Avatar.vue'
import ProjStatus from '@/components/ProjStatus.vue'

export default {
    name: 'TxnChart',
    components: { Alert, TopNavigation, Popper, Datepicker, /* Avatar,  */ProjStatus },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const maxDisplay = ref(8)
        const filterDateFr  = ref('')
        const filterDateTo  = ref('')
       
        const entityOpt = ref('FOLDER') // FOLDER, DOCUMENT, CREDITSPENT
        const userOpt = ref('FOLDER') // FOLDER, DOCUMENT, CREDITSPENT

        const arrEntUsage = ref([])
        const arrUserUsage = ref([])
        

        watch([filterDateFr, filterDateTo, entityOpt], () => {
            // console.info('- - WATCH: Filter')
            topUsageByEntity(entityOpt.value)
            topUsageByUser(entityOpt.value)
            
        })

        const setDateFr = (val) => {
            filterDateFr.value = func.convDateTimeFormat(val, 'date')
        }

        const setDateTo = (val) => {
            filterDateTo.value = func.convDateTimeFormat(val, 'date')
        }

        const topUsageByUser = (ord) => {
         
            const p = { 
                dateFrom: filterDateFr.value + ' 00:00:00',
                dateTo: filterDateTo.value + ' 23:59:59',
                orderBy: [
                    {
                        field: ord,
                        order: 'desc'
                    }
                ],
                limit: '0, ' + maxDisplay.value
            }

            // console.info('topUsageByUser REQ', JSON.stringify(p))
            axios.post('/report/su/signon/usage/top/user', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        arrUserUsage.value = res.data.data
                        // console.info('topUsageByUser', JSON.stringify(res.data.data))

                    } else {
                        func.addLog('txnchart', 'topUsageByUser', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('txnchart', 'topUsageByUser - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }


        const topUsageByEntity = (ord) => {
         
            const p = { 
                dateFrom: filterDateFr.value + ' 00:00:00',
                dateTo: filterDateTo.value + ' 23:59:59',
                orderBy: [
                    {
                        field: ord,
                        order: 'desc'
                    }
                ],
                limit: '0, ' + maxDisplay.value
            }

            // console.info('topUsageByEntity', JSON.stringify(p))
            axios.post('/report/su/signon/usage/top/entity', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        arrEntUsage.value = res.data.data
                        // console.info('topUsageByEntity', JSON.stringify(res.data.data))

                    } else {
                        func.addLog('txnChart', 'topUsageByEntity', res)
                    }
                })
                .catch((error) => {
                    func.addLog('txnChart', 'topUsageByEntity - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {

            filterDateFr.value = func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 8), 'date')
            filterDateTo.value = func.convDateTimeFormat(new Date(), 'date')

            console.info('filterDateFr', filterDateFr.value, filterDateTo.value)

        })

        return {
            alert, closeAlert, route, router, func, store, 
            maxDisplay, filterDateFr, filterDateTo, setDateFr, setDateTo,
            entityOpt, arrEntUsage, userOpt, arrUserUsage, 
        }
    }
}
</script>

<style>
    .input-group-text {
        background-color: var(--bs-primary);
        color: var(--bgcolor-body-color);
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: #31d2f2;
        color: white;
    }
</style>